<template>
  <div class="container">
    <!-- 卡片 -->
    <el-card class="box-card">
      <!-- 添加按钮 -->
      <el-button type="primary" size="small" @click="teacherShow = true">添加目录</el-button>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <!-- <el-table-column align="center" type="index" label="序号" width="100"></el-table-column> -->
        <el-table-column align="center" label="id" prop="id" width="150"></el-table-column>
        <el-table-column align="center" label="目录名称" prop="contentsName"></el-table-column>
        <el-table-column align="center" label="目录顺序" prop="contentsOrder"></el-table-column>
        <el-table-column align="center" width="150" label="目录类型">
          <template slot-scope="scope">
            <el-tag :type="scope.row.contentsType == 0 ? '' :scope.row.contentsType == 1 ? 'success' : 'warning'"
                    disable-transitions>
              {{ scope.row.contentsType == 0 ? '视频 + 音频' : scope.row.contentsType == 1 ? '视频' : '音频' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="时长" prop="duration"></el-table-column>
        <el-table-column align="center" label="操作" width="250">
          <template slot-scope="scope">
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemove(scope.row.id)" icon="el-icon-delete" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 弹出层 -->
    <el-dialog :top="form.contentsType == 0 ? '6vh' : '15vh'" :title="form.id ? '编辑线上目录' : '添加线上目录'"
               :visible.sync="teacherShow" width="50%" :before-close="handleClose">
      <!-- 表单 -->
      <el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm" :rules="rules">
        <el-form-item label="目录名称:" prop="contentsName">
          <el-input @focus="clearValidate('contentsName')" v-model="form.contentsName" placeholder="请输入目录名称"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="目录顺序:" prop="contentsOrder">
          <el-input @focus="clearValidate('contentsOrder')" v-model="form.contentsOrder" type="number"
                    placeholder="请输入目录名称" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="目录类型:">
          <el-radio-group v-model="form.contentsType">
            <el-radio v-for="item in radioList" :key="item.label" :label="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传视频:" prop="videoUrl" v-if="form.contentsType != 2">
          <div style="display: flex;">
            <el-upload drag action="/api/file/upload" :file-list="fileVideoList" :limit="1" :before-remove="removeVidio"
                       :on-exceed="exceedVidio" :on-success="successVideo" :multiple="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
            <div v-if="form.videoUrl" class="video">
              <video controls :src="form.videoUrl" class="video"></video>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="上传音频:" prop="audioUrl" v-if="form.contentsType != 1">
          <div style="display:flex;">
            <el-upload drag action="/api/file/upload" :file-list="fileAudioList" :before-remove="removeAudio"
                       :on-exceed="exceedAudio" :limit="1" :on-success="successAudio" :multiple="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
            <div v-if="form.audioUrl" class="audio">
              <audio :src="form.audioUrl" controls></audio>
            </div>
          </div>

        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {catalogueList, catalogueDel, catalogueAddOnSet} from "../../apis/course"
import {catalogue} from "@/utils/rules"

let courseId = null
export default {
  data() {
    return {
      teacherShow: false,
      loading: true,
      tableData: [],
      rules: catalogue.rules,
      form: {
        contentsName: "", //	目录名称	query	false
        contentsOrder: "",	 //目录顺序	query	false
        contentsType: 1, //	目录类型(0=全部，1=视频，2=音频)	query	false
        courseId: "", //	课程id	query	false
        videoUrl: '', // 视频地址
        audioUrl: "", //	音频地址	query	false
      },
      radioList: [{
        value: '全部',
        label: 0
      }, {
        value: '视频',
        label: 1
      }, {
        value: '音频',
        label: 2
      }],
      fileVideoList: [], // 视频上传列表
      fileAudioList: [], // 音频上传列表
    }
  },
  mounted() {
    courseId = this.$route.query.id
    this.getDataList()
  },
  watch: {
    '$route.query.id': function (val) {
      courseId = val
      if (val) {
        this.getDataList()
      }
    }
  },
  methods: {
    //上传视频
    successVideo(response) {
      this.form.videoUrl = response.data
      this.clearValidate('videoUrl') // 清空校验
      this.$message({message: '上传成功', type: 'success'});
    },
    removeVidio(file) {
      this.form.videoUrl = ''
    },
    exceedVidio(file) {
      this.$message({message: '超出视频上传限制', type: 'error'});
    },

    // 上传音频
    successAudio(response, file) {
      this.clearValidate('audioUrl') // 清空校验
      this.$message({message: '上传成功', type: 'success'});
      this.form.audioUrl = response.data
    },
    exceedAudio(file) {
      this.$message({message: '上传音频不能超过1个', type: 'error'})
    },
    removeAudio(file) {
      this.form.audioUrl = ''
    },

    // 获取目录列表
    async getDataList() {
      const res = await catalogueList({courseId})
      this.tableData = res.data
      setTimeout(() => {
        this.loading = false
      }, 50)
    },

    // 编辑课程目录
    handlerEdit(item) {
      this.teacherShow = true
      const newObj = {...item}
      this.form = newObj
      this.form.contentsType = +item.contentsType
    },

    // 删除线上课程目录
    handlerRemove(id) {
      this.$confirm('此操作将永久删除课程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await catalogueDel({id})
        this.$message({message: '删除成功', type: "success"})
        this.getDataList()
      }).catch(err => {
      })
    },

    // 提交编辑或修改
    submit() {
      this.$refs.form.validate(async val => {
        if (!val) return
        this.form.courseId = courseId
        await catalogueAddOnSet(this.form)
        this.$message({message: this.form.id ? '编辑成功' : '添加成功', type: 'success'})
        this.teacherShow = false
        this.handleClose()
        this.getDataList()
      })
    },
    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },

    // 清空内容 初始化值
    handleClose() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.teacherShow = false
      this.form.contentsType = 1
      this.fileVideoList = []
      this.fileAudioList = []
      delete this.form.id // 删除对象里面的id
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
  margin-right: 20px;
}

/deep/ video {
  border: 1px dashed #d9d9d9 !important;
}

/deep/ .el-input__inner {
  width: 400px;
}

.audio,
.video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  flex: 1;
  border: 1px dashed #d9d9d9;
}
</style>